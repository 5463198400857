import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-news-single-page',
  templateUrl: './news-single-page.component.html',
  styleUrls: ['./news-single-page.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '200px',
        opacity: 1,
        display:'block',
      })),
      state('closed', style({
        width: '0px',
        opacity: 0,
        display:'none',
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class NewsSinglePageComponent implements OnInit {
  
  singleNews:any;
  newNewsId:any;
  singleNewsLoaded:boolean;
  isExternal:boolean;
  href:string;
  lastString:string;
  receiveRefData:any;
  isOpen = false;
  isOverlay = false;
  fullUrl:any;
  newsType:any;
  metaTagLoaded:boolean;
  metaTagNews:any;
  newsid:any;
  envSiteName:any;
  EventDifferentType: string;
  EventDifferentType1: string;
  SourceType:string;
  ReadButton:boolean;
  titlePrefix:string;
  loaded:boolean;
  iData:any;

  constructor(private httpclientService: HttpclientService,public router:Router,private titleService: Title,private metaTagService: Meta, private activatedRoute: ActivatedRoute) { 
    this.envSiteName=environment.siteName;
  }

  

  ngOnInit(): void {
    this.metaTags();
    this.singleNewData();
    this.LogoData();
    this.fullUrl=window.location.href;
    this.newsid = this.activatedRoute.snapshot.params.id;
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+window.location.hostname)
      .subscribe(
        (  items: any) => {
          this.iData = items;
          this.titlePrefix = this.iData.PreSite;
          this.loaded = true;
        });
  }

  receiveRefresh($event){
    this.resetUsers();
    this.newNewsId=$event;
    this.singleNewData();
     
  }
  
  singleNewData(): void {
    if(this.newNewsId != null){
      this.newsid=this.newNewsId;
       
  }else{    
    this.newsid = this.activatedRoute.snapshot.params.id;
  }
    this.isExternal= false;
    this.singleNewsLoaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleNews/GetSingleNews?siteName='+this.envSiteName+'&NewsID='+this.newsid)
      .subscribe(
        items => {
          this.singleNews = items[0];
          this.newsType = items[0].NewsTypeName;
          this.EventDifferentType1 = items[0].EventDifferentType;
          this.SourceType=items[0].SourceType;
          if(this.SourceType='0')
          {
            this.ReadButton=false;
          }
          
          this.titleService.setTitle(this.singleNews.Title+' | '+this.titlePrefix);
          this.metaTagService.updateTag(
            { name: 'og:description', content: this.singleNews.Description}
          );
          this.metaTagService.updateTag(
            { name: 'og:title', content: this.singleNews.Title+' | '+this.titlePrefix}
          );
          this.metaTagService.updateTag(
            { name: 'og:image', content: this.singleNews.ImageUrl}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.singleNews.Description}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.singleNews.Title+' | '+this.titlePrefix}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.singleNews.ImageUrl}
          );
          //seo code for tags end

          if(this.newsType != 'featured-news'){
             this.isExternal= true;
          }
          this.singleNewsLoaded=true;
          console.log("singleNews:",this.singleNews);
          console.log("news TypeName:",this.newsType);
        });
  }
  resetUsers(): void {
    this.singleNews = null;
    this.singleNewsLoaded = false;
    this.lastString=null;
    this.href=null;
  }
  shareClick(){
    this.isOpen = !this.isOpen;
  }
  metaTags(): void {
    this.metaTagLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName=All-Events')
      .subscribe(
        (  items: any) => {
          this.metaTagNews = items[0];
          this.metaTagLoaded = true;
          this.EventDifferentType = items[0].EventDifferentType;
          console.log("meta Data news",this.metaTagNews)
        });
  }

}
