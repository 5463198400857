import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-single-interview-spotlight',
  templateUrl: './single-interview-spotlight.component.html',
  styleUrls: ['./single-interview-spotlight.component.scss']
})
export class SingleInterviewSpotlightComponent implements OnInit {

  @Input() domainData : any;
  spotLightDatas: any;
  loaded: boolean;
  firstString: string;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService,private routeUrl: Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.spotLightData();
  }
  redirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.routeUrl.navigate(['/companies/all-companies/'+getUrl+"/"+getId]);
    }
  }
  spotLightData(): void {
    this.loaded =false;
    console.log("domainData",this.domainData);
    
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleInterview/GetSingleInterviewSpotlight?SiteName='+this.envSiteName+'&DomainName='+this.domainData)
    .subscribe(
      items => {
        this.spotLightDatas =items[0];
        console.log( this.spotLightDatas);
        this.loaded =true;
      }
    );
  }

}
