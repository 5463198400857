import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-all-events',
  templateUrl: './all-events.component.html',
  styleUrls: ['./all-events.component.scss']
})
export class AllEventsComponent implements OnInit {

  EventNewsDatas: any;
  EventLoaded: boolean;
  WebinarDatas:any;
  WebinarLoaded:boolean;
  ConferenceDatas:any;  
  ConferenceLoaded:boolean;
  OnDemandWebinarDatas:any;
  OnDemandWebinarLoaded:boolean;
  pastEventsDatas: any;
  pastEventsLoaded: boolean;
  envSiteName:any;
  firstString:string;

  constructor(private httpclientService: HttpclientService,private routeUrl: Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
     this.AllEvents();
     this.AllLiveWebinars();
     this.AllUpComingConferences();
     this.AllOnDemandWebinars();
  }


  AllUpComingConferences(): void {
    if(this.ConferenceLoaded!=true){
    this.ConferenceLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventTop3UpCommingConferences?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.ConferenceDatas = items;
          this.ConferenceLoaded = true;
          console.log("Conference AllUpComingConferences",this.ConferenceDatas)
          window.scrollTo(0,0);
        });
      }
  }

  AllEvents(): void {
    if(this.EventLoaded!=true){
    this.EventLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventTop3PastConferences?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.EventNewsDatas = items;
          this.EventLoaded = true;
          console.log("events Past Conference AllEvents",this.EventNewsDatas)
          window.scrollTo(0,0);
        });
      }
  }

  AllLiveWebinars(): void {
    if(this.WebinarLoaded!=true){
    this.WebinarLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventTop3LiveWebinars?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.WebinarDatas = items;
          this.WebinarLoaded = true;
          console.log("webinar AllWebinars",this.WebinarDatas)
          window.scrollTo(0,0);
        });
      }
  }

  

  AllOnDemandWebinars(): void {
    if(this.OnDemandWebinarLoaded!=true){
    this.OnDemandWebinarLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventTop3OnDemandWebinars?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.OnDemandWebinarDatas = items;
          this.OnDemandWebinarLoaded = true;
          console.log("OnDemandWebinar AllOnDemandWebinarDatas",this.OnDemandWebinarDatas)
          window.scrollTo(0,0);
        });
      }
  }
  redirect(getUrl:any,getId:any)
  {
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https")
    {
      window.open(getUrl, "_blank");
    }else
    {
      this.routeUrl.navigate(['/events/on-demand-webinars/'+getUrl+"/"+getId]);
    }
  }

}
