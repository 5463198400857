import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../../application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { environment } from '../../../../../../../src/environments/environment';

const STATE_KEY_ITEMS = makeStateKey('items');


@Component({
  selector: 'app-upcoming-conferences',
  templateUrl: './upcoming-conferences.component.html',
  styleUrls: ['./upcoming-conferences.component.scss']
})
export class UpcomingConferencesComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 9;
  UpcomingConferenceDatas:any;  
  ConferenceLoaded:boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;

  constructor(private state: TransferState,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.UpcomingConferences();
  }

  UpcomingConferences(): void {
  //  if(this.ConferenceLoaded!=true){
      this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.ConferenceLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventAllUpCommingConferences?siteName='+this.envSiteName+'&pageNumber='+this.page)
      .subscribe(
        items => {
          const platform = isPlatformBrowser(this.platformId) ?
          'in the browser' : 'on the server';
          this.UpcomingConferenceDatas = items;
          this.count=this.UpcomingConferenceDatas[0].TotalDataCount;
          this.ConferenceLoaded = true;
          this.state.set(STATE_KEY_ITEMS, <any> items);
           console.log("Upcoming AllConferences",this.UpcomingConferenceDatas)

           window.scrollTo(0,0);
        });
    //  }
  }

  resetUsers(): void {
    this.UpcomingConferenceDatas = null;
    this.ConferenceLoaded = true;
  }
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.UpcomingConferences();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}

}
