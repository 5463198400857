import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-spotlight',
  templateUrl: './spotlight.component.html',
  styleUrls: ['./spotlight.component.scss']
})
export class SpotlightComponent implements OnInit {
 

  iData: any;
  sData: any;
  loaded: boolean;
  companyDomain:string;
  Publishedsite : string;
  envSiteName:any;
  firstString: string;
  constructor(private httpclientService: HttpclientService, public router:Router) {

     
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    debugger;
    this.interviewData();
    
  }
  // redirect(ResourceType:any,getUrl:any,getId:any){
  //   this.firstString = getUrl.substring(0,5);
  //   if(this.firstString ==="https"){
  //     window.open(getUrl, "_blank");
  //   }else{
  //     this.router.navigate(["/"+getUrl+"/"+getId]);
     
  //   }
  // }
  
  interviewData(): void {
    debugger;
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetHomeInterview?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.Publishedsite = "";
          this.iData = items[0];
          this.loaded = true;
          console.log("home",this.iData);
          this.companyDomain=items[0].CompanyDomain;
          this.Publishedsite = items[0].Publishedsite.toLowerCase();
          if(this.Publishedsite.trim() =="Deck7.io")
          {
            this.Publishedsite ="";
            this.Publishedsite = this.envSiteName.trim();
          }
          console.log("companyDomain",items[0].iData);
          this.spotlightData();
        });
  }
  spotlightData(): void {
    debugger;
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetHomespotlight?siteName='+this.Publishedsite +'&domainName='+this.companyDomain)
      .subscribe(
        items => {
          this.sData = items[0];
          this.loaded = true;
          console.log("spotlight",this.sData);
        });
  }

}
