import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-company-resources',
  templateUrl: './single-company-resources.component.html',
  styleUrls: ['./single-company-resources.component.scss'],
})
export class SingleCompanyResourcesComponent implements OnInit {
  //@Input('companyNameResourceData')
  @Input() companyNameResourceData: any;
  abc : string ;
  href:string;
  lastString:string;
  ResourcesLoaded:  boolean;
  ResourcesDatas: any;
  firstString: string;
  envSiteName: any;
  constructor(private httpclientService: HttpclientService,public router:Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(){
   
    this.resourcesData();
  }
  resourcesData(): void{
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
    this.ResourcesLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/SingleCompanies/GetTop4SingleCompaniesResources?siteName=' + this.envSiteName+'&CompanyName='+this.companyNameResourceData)
      .subscribe(
        items => {
          this.ResourcesDatas = items;
          console.log(this.ResourcesDatas);
        //  this.directRoute = items[0].RouteURL;
          this.ResourcesLoaded = true;
          console.log("single ResourcesLoaded", this.ResourcesDatas);
        });
  }
  redirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,4);
    console.log("single resources companies",this.firstString)
    if(this.firstString ==="http"){
      window.open(getUrl, "_blank");
    }else{
      this.router.navigate(['/events/upcoming-conferences/'+getUrl+"/"+getId]);
    }
  }
}
