import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-global-news',
  templateUrl: './global-news.component.html',
  styleUrls: ['./global-news.component.scss']
})
export class GlobalNewsComponent implements OnInit {

  featureNewsDatas: any;
  featureLoaded: boolean;
  trendingNewsDatas: any;
  
  trendingLoaded: boolean;
  archivedLoaded: boolean;
  archivedNewsDatas: any;
  envSiteName:any;


  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"}
  ];
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  slideConfigFeatureNews = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };
  slideConfigTrendingNews = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };
  constructor(private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
   }
  ngOnInit(): void {
    this.featuredNews();
    this.trendingNews();
    this.archivedNews();
  }

  featuredNews(): void {
    this.featureLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/News/GetTop9FutureNewsDetails?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.featureNewsDatas = items;
          this.featureLoaded = true;
          console.log("news featureNewsDatas",this.featureNewsDatas)
          window.scrollTo(0,0);
        });
  }
  
  trendingNews(): void {
    this.trendingLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/News/GetTop9TrendingNewsDetails?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.trendingNewsDatas = items;
          this.trendingLoaded = true;
          console.log("news trendingNewsDatas",this.trendingNewsDatas)
          window.scrollTo(0,0);
        });
  }
  archivedNews(): void{
    this.archivedLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/News/GetTop9ArchivedNewsDetails?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.archivedNewsDatas = items;
        this.archivedLoaded = true;
        console.log("News ArchivedNews Data",this.archivedNewsDatas);
      }
    )
  }

}
