import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-events-company',
  templateUrl: './events-company.component.html',
  styleUrls: ['./events-company.component.scss']
})
export class EventsCompanyComponent implements OnInit {
  eventDatas: any;
  loaded: boolean;
  firstString: string;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService, private routeUrl: Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.eventsData();
  }
  redirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.routeUrl.navigate(['/events/upcoming-conferences/'+getUrl+"/"+getId]);
    }
  }
  eventsData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalCompanies/GetGlobalCompanyTOP3Events?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.eventDatas = items;
        this.loaded = true;
        console.log("Events fiuiufeife Data ", this.eventDatas);
      }
    );
  }

}
