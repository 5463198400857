import { BrowserModule,Title } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AdsenseModule } from 'ng2-adsense';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/general/home/home.component';
import { HeaderComponent } from './modules/application/header/header.component';
import { FooterComponent } from './modules/application/footer/footer.component';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';
import { CompaniesModule } from './modules/general/companies/companies.module';
import { NewsModule } from './modules/general/news/news.module';
import { EventsModule } from './modules/general/events/events.module';
import { ResourcesModule } from './modules/general/resources/resources.module';
import { BannerComponent } from './modules/general/home/banner/banner.component';
import { SpotlightComponent } from './modules/general/home/spotlight/spotlight.component';
import { HomeNewsComponent } from './modules/general/home/home-news/home-news.component';
import { HomearticlesComponent } from './modules/general/home/home-articles/home-articles.component';
import{HomewhitepapersComponent} from './modules/general/home/home-whitepapers/home-whitepapers.component';
import{HomeresourcesComponent} from'./modules/general/home/home-resources/home-resources.component';
import { HomeEventsComponent } from './modules/general/home/home-events/home-events.component';
import { ScrollTopComponent } from './modules/application/footer/scroll-top/scroll-top.component';
import { HorizontalAdComponent } from './modules/application/horizontal-ad/horizontal-ad.component';
import { SquareAdComponent } from './modules/application/square-ad/square-ad.component';
import { NewsletterPopComponent } from './modules/application/header/newsletter-pop/newsletter-pop.component';
import { MemberLoginPopComponent } from './modules/application/header/member-login-pop/member-login-pop.component';
import { MemberSignupPopComponent } from './modules/application/header/member-signup-pop/member-signup-pop.component';
import { TermsPageComponent } from './modules/application/terms-page/terms-page.component';
import { PrivacyPageComponent } from './modules/application/privacy-page/privacy-page.component';
import { CookiesComponent } from './modules/application/cookies/cookies.component';
import { GlobalSearchComponent } from './modules/application/header/global-search/global-search.component';
import { ThankYouPageComponent } from './modules/application/thank-you-page/thank-you-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    BannerComponent,
    SpotlightComponent,
    HomeNewsComponent,
    HomeresourcesComponent,
    HomearticlesComponent,
    HomewhitepapersComponent,
    HomeEventsComponent,
    ScrollTopComponent,
    HorizontalAdComponent,
    SquareAdComponent,
    NewsletterPopComponent,
    MemberLoginPopComponent,
    MemberSignupPopComponent,
    TermsPageComponent,
    PrivacyPageComponent,
    CookiesComponent,
    GlobalSearchComponent,
    ThankYouPageComponent
  ],
  imports: [
    // shown passing global defaults (optional)
    AdsenseModule.forRoot({
      adClient: 'ca-pub-4564607713805390',
      adSlot: 300949867,
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CompaniesModule,
    NewsModule,
    EventsModule,
    ResourcesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SlickCarouselModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    Title,
    CookieService
  ],
  // exports: [HorizontalAdComponent], 
  bootstrap: [AppComponent]
})
export class AppModule { }


 