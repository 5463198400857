import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-single-thought-leader',
  templateUrl: './single-thought-leader.component.html',
  styleUrls: ['./single-thought-leader.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '200px',
        opacity: 1,
        display:'block',
      })),
      state('closed', style({
        width: '0px',
        opacity: 0,
        display:'none',
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class SingleThoughtLeaderComponent implements OnInit {

   singleInterviewDatas:any;
   newNewsId:any;
   singleInterviewLoaded:boolean;
   envSiteName:any;
   titlePrefix:string;

   constructor(private httpclientService: HttpclientService,public router:Router,private titleService: Title,private metaTagService: Meta, private activatedRoute: ActivatedRoute) {
    this.envSiteName=environment.siteName;
    }
  @Input() domainData: any;
  href:string;
  lastString:string;
  receiveRefData:any;
  isOpen = false;
  isOverlay = false;
  fullUrl:any;
  leadersid: any;
  companyNameResource: string;
  loaded:boolean;
  iData:any;

   ngOnInit(): void {
     this.singleInterviewData();
     this.LogoData();
     this.fullUrl=window.location.href;
     this.leadersid = this.activatedRoute.snapshot.params.id;
   }
   LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+this.envSiteName)
      .subscribe(
        (  items: any) => {
          this.iData = items;
          this.titlePrefix = this.iData.PreSite;
          this.loaded = true;
        });
  }
   
  receiveRefresh($event){
    this.resetUsers();
    this.newNewsId=$event;
    console.log("receiveRefData",this.lastString);
    this.singleInterviewData();
    console.log("receiveRefData",this.lastString);
  }
  
  singleInterviewData(): void {
      this.leadersid=this.newNewsId;
  // }else{
    this.leadersid =  this.activatedRoute.snapshot.params.id;
  // }
    this.singleInterviewLoaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleInterview/GetSingleInterviewThoughtLeaderByID?SiteName='+this.envSiteName+'&interviewID='+ this.leadersid)
      .subscribe(
        items => {
          this.singleInterviewDatas = items[0];
          this.singleInterviewLoaded=true;
          this.companyNameResource = items[0].CompanyName;
          this.domainData = items[0].CompanyDomain;
          console.log("single companyNameResource Datas",this.companyNameResource);
          console.log("single Interview Datas",this.singleInterviewDatas);


          //seo code for tags
          this.titleService.setTitle(this.singleInterviewDatas.InterviewTitle+' | '+this.titlePrefix);
          this.metaTagService.updateTag(
            { name: 'og:description', content: this.singleInterviewDatas.InterviewDetails}
          );
          this.metaTagService.updateTag(
            { name: 'og:title', content: this.singleInterviewDatas.InterviewTitle+' | '+this.titlePrefix}
          );
          this.metaTagService.updateTag(
            { name: 'og:image', content: this.singleInterviewDatas.InterviewImage}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.singleInterviewDatas.InterviewDetails}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.singleInterviewDatas.InterviewTitle+' | '+this.titlePrefix}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.singleInterviewDatas.InterviewImage}
          );
          //seo code for tags end
        });
   }
  resetUsers(): void {
    this.singleInterviewDatas = null;
    this.singleInterviewLoaded = false;
    this.leadersid=null;
    this.href=null;
  }
  shareClick(){
    this.isOpen = !this.isOpen;
  }

}
