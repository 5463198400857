import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-other-news',
  templateUrl: './other-news.component.html',
  styleUrls: ['./other-news.component.scss']
})


export class OtherNewsComponent implements OnInit {

  refreshEmit:any;
  @Output() refreshEmitter= new EventEmitter<string>();
  @Input() resourceTypeName: string;
  newsType:any;
  isExternal:boolean;
  moreNewsLoaded:boolean;
  moreNewsDatas:any;
  href:any;
  lastString:any;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService,public router:Router) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.moreNewsData();
  }


  refreshReq(newsId:any){
    this.resetUsers();
    
    this.refreshEmit=newsId;
    this.refreshEmitter.emit(this.refreshEmit);
    this.lastString=newsId;
    this.moreNewsData();
    
  }

  moreNewsData(){
    if(this.lastString==null){
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
  }
    console.log("other news11111",this.resourceTypeName);
    console.log("other news22222",this.resourceTypeName)
    this.isExternal= false;
    this.moreNewsLoaded=false;
    this.moreNewsDatas = null;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleNews/GetTop4SingleOtherNews/'+this.lastString+'?siteName='+this.envSiteName+'&typeName='+this.resourceTypeName)
      .subscribe(
        items => {
          this.moreNewsDatas = items;
          this.newsType = items[0].NewsTypeName;
          this.moreNewsLoaded=true;
          console.log("newsExternal:",this.newsType);
          console.log("news moreNewsDatas",this.moreNewsDatas)
        });
  }

  resetUsers(): void {
    this.moreNewsDatas = null;
    this.moreNewsLoaded = false;
    this.lastString=null;
    this.href=null;
  }
}
