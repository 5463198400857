<!-- <p class="mb-1 para-2-c-2">
    <a href="/">Home</a> > <a href="/companies">Companies</a> > <a href="/companies/marketplace" class="para-2-c-1">Marketplace</a>
</p> -->
<div class="row">
    <!-- <div class="col-md-9"> -->
    <div class="col-md-3 d-none d-md-block">
        <app-c-suiteondeck></app-c-suiteondeck>
        
    </div>
    <div class="col-md-6">
        <h1 class="text-uppercase ">Companies</h1>
        
        <p class="compStartName"><span>Companies starting with :</span><span></span>
            <a style="cursor:pointer" #Charid id="Charid" (click)="handlePageChangeForSearch(charData)" *ngFor="let charData of charsArrays"> {{charData}} </a>

        </p>
        <div class="row d-md-flex mt-3 " *ngIf="companyLoaded">
            <div class="col-md-12 mb-5" *ngFor="let companiesData of companiesDatas | paginate : {
                    itemsPerPage: pageSize,
                    currentPage: page,
                    totalItems: count
                  };
                  let i = index" [class.active]="i == currentIndex">
                 
                <!-- <div class="cursor-p" (click)="redirect(companiesData.RouteURL,companiesData.ID)"> -->
                    <div class="row compMT WrapArt">
                        <div class="col-md-4 WrapArtT">
                            <div class="h-100 w-100 text-center ">
                            <a target="_blank" routerLink='/companies/{{companiesData.RouteURL}}'>
                                <img class="img-fluid" [src]="companiesData.Logo" alt="marketplace image">
                            </a>  
                            </div>
                        </div>
                        <div class="col-md-8">
                            <a target="_blank" routerLink='/companies/{{companiesData.RouteURL}}'>
                            <h4 class="text-uppercase MainTitle" [innerHTML]="companiesData.CompanyName"></h4>
                            </a>
                            <!-- <div class="col-md-6"> -->
                          
                            <!-- </div> -->
                            <p class="para-1 text-justify text-height-4 DescPara" [innerHTML]="companiesData.Description"></p>
                            <a class="para-2 para-2-c-2 Read_More" target="_blank" routerLink='{{companiesData.RouteURL}}'>Read More</a>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
        </div>

        <div class="row">
            <div class="col-12 p-0 text-center mt-md-0" *ngIf="count>9">
                <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
            </div>
        </div>
        
    </div>
    <!-- </div> -->
    <div class="col-md-3">
        <app-spotlight-company></app-spotlight-company>
        <div class="d-md-none SpotEveRes">
            <app-c-suiteondeck></app-c-suiteondeck>     
        </div>
        <app-events-company></app-events-company>
    </div>
</div>