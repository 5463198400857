<div class="row">
    <div class="col-md-9">
        <router-outlet>
        </router-outlet>
    </div>
    <div class="col-md-3">
        <app-spotlight-news></app-spotlight-news>
        <app-resources-news></app-resources-news>
        <app-events-news></app-events-news>
    </div>
</div>