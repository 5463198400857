<div class="row" *ngIf='OnDemandWebinarLoaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex WrapArt" *ngIf="OnDemandWebinarLoaded">
    <div class="col-md-4" *ngFor="
    let ondemandwebinarData of OnDemandWebinarDatas | paginate : {
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: count
      };
    let i = index
    " [class.active]="i == currentIndex" >
     <div>
        <!-- <a target="_blank" routerLink="" > -->
           
        <a [href]="ondemandwebinarData.RouteURL" target="_blank">
            <img class="img-fluid pb-2 image-hight-news" [src]="ondemandwebinarData.ImageUrl" alt="webinar image">
            <h4 class="text-height-4 card-header-height-1 text-uppercase MainTitle" [innerHTML]="ondemandwebinarData.Name">
            </h4>
        </a> 
            <p class="para-2 mb-2">{{ondemandwebinarData.Sponsors}}
                <span *ngIf='ondemandwebinarData.IsSponcered==true'>| <font>Sponsored</font></span>
            </p>
            <p class="para-1 text-justify text-height-4 card-header-height-4 DescPara">{{ondemandwebinarData.Details}}</p>
            <a  [href]="ondemandwebinarData.RouteURL">
            <a class="para-2 para-2-c-2 Read_More" target="_blank" [href]="ondemandwebinarData.RouteURL">Watch Now</a> 
            </a>
         </div> 
    </div>
</div>

<div class="row">
  <div class="col-12 p-0 text-center mt-md-0" *ngIf="count>9">
      <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>
</div>