import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-single-whitepaper-page',
  templateUrl: './single-whitepaper-page.component.html',
  styleUrls: ['./single-whitepaper-page.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '200px',
        opacity: 1,
        display:'block',
      })),
      state('closed', style({
        width: '0px',
        opacity: 0,
        display:'none',
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class SingleWhitepaperPageComponent implements OnInit {
  resourceid : number;
  newWhitepapersId:any;
  resourcetype : string;
  pDFUrl: string;
  singleWhitePaperDatas: any;
  singleWhitePaperLoaded: boolean;
  href:string;
  lastString:string;
  isOpen = false;
  isOverlay = false;
  fullUrl:any;
  whitepapersid:any;
  firstString:string;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  titlePrefix:string;
  loaded:boolean;
  iData:any;


  constructor(private httpclientService: HttpclientService,public router:Router,private titleService: Title,private metaTagService: Meta, private activatedRoute: ActivatedRoute) {
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.singleWhitePaperData();
    this.LogoData();
     this.fullUrl=window.location.href;
     this.whitepapersid = this.activatedRoute.snapshot.params.id;
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+window.location.hostname)
      .subscribe(
        (  items: any) => {
          this.iData = items;
          this.titlePrefix = this.iData.PreSite;
          this.loaded = true;
        });
  }
  

  receiveRefresh($event){
    this.resetUsers();
    this.newWhitepapersId=$event;
    console.log("receiveRefData",this.lastString);
    this.singleWhitePaperData();
    console.log("receiveRefData",this.lastString);
  }
  
  singleWhitePaperData(): void
  {
    if(this.newWhitepapersId != null){
      this.whitepapersid=this.newWhitepapersId;
      console.log("lastString newWhitepaperId",this.lastString);
  }else{
    this.whitepapersid =  this.activatedRoute.snapshot.params.id;
  }
    this.singleWhitePaperLoaded=false;
    
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SinglwWhitePaper/GetTop1SingleWhitePaperById?siteName='+this.envSiteName+'&id='+this.whitepapersid)
      .subscribe(
        items => {
          this.singleWhitePaperDatas = items[0];
          this.resourceid = items[0].ID;
          this.resourcetype = items[0].ResourceType;
          this.pDFUrl = items[0].PDFUrl;
          this.singleWhitePaperLoaded=true;
          console.log("Single WhitePaper Datas ",this.singleWhitePaperDatas);
          //seo code for tags
        this.titleService.setTitle(this.singleWhitePaperDatas.WhitePaperTitle+' | '+this.titlePrefix);
        this.metaTagService.updateTag(
          { name: 'og:description', content: this.singleWhitePaperDatas.Description}
        );
        this.metaTagService.updateTag(
          { name: 'og:title', content: this.singleWhitePaperDatas.WhitePaperTitle+' | '+this.titlePrefix}
        );
        this.metaTagService.updateTag(
          { name: 'og:image', content: this.singleWhitePaperDatas.imageurl}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.singleWhitePaperDatas.Description}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.singleWhitePaperDatas.WhitePaperTitle+' | '+this.titlePrefix}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.singleWhitePaperDatas.imageurl}
        );
        //seo code for tags end


        });
  }

  resetUsers(): void {
    this.singleWhitePaperDatas = null;
    this.singleWhitePaperLoaded = false;
    this.lastString=null;
    this.href=null;
    this.onActivate();
  }

  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}


  shareClick(){
    this.isOpen = !this.isOpen;
  }
 
}
