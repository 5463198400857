import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-globel-resources',
  templateUrl: './globel-resources.component.html',
  styleUrls: ['./globel-resources.component.scss']
})
export class GlobelResourcesComponent implements OnInit {

  href:any;
  lastString:string;

  constructor(private router:Router) { }

  ngOnInit(): void {
   
  }
  ngOnChanges(): void{
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1)
    console.log("this.lastString",this.lastString);
  }

}
