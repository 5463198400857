import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-resources-spotlight',
  templateUrl: './resources-spotlight.component.html',
  styleUrls: ['./resources-spotlight.component.scss']
})
export class ResourcesSpotlightComponent implements OnInit {
  loaded: boolean;
  spotLightDatas: any;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.spotLightData()
  }
  spotLightData(): void
  {
    this.loaded =false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalReources/GetTop1GlobalReourcesSpotLight?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.spotLightDatas = items[0];
        this.loaded = true;
        console.log("SpotLight Resorces Data:", this.spotLightDatas);
      }
    );
  }
}
