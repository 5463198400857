import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-all-conferences',
  templateUrl: './all-conferences.component.html',
  styleUrls: ['./all-conferences.component.scss']
})
export class AllConferencesComponent implements OnInit {
  href:any;
  lastString:string;
  MetaTagLoadedPast: boolean;
  MetaTagDataPast: any;
  envSiteName:any;
  currentPageName:string;

  constructor(private router:Router,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
    this.AllMetaTag();
  }

  AllMetaTag(): void {
    if(this.lastString=="past-conferences"){
      this.currentPageName='PastEvents';
    }else if(this.lastString=="upcoming-conferences"){
      this.currentPageName='UpcomingConferences';
    }

    if(this.currentPageName){
    this.MetaTagLoadedPast = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+this.currentPageName)
    .subscribe(
      items => {
        this.MetaTagDataPast = items[0];
        this.MetaTagLoadedPast=true;
        console.log('this.MetaTagDataPast',this.MetaTagDataPast);
  
  
          //seo code for tags
          this.titleService.setTitle(this.MetaTagDataPast.PageTitle);
  
          this.metaTagService.updateTag(
          { name: 'og:description', content: this.MetaTagDataPast.OgDescription}
          );
  
          this.metaTagService.updateTag(
            { name: 'og:title', content: this.MetaTagDataPast.PageTitle}
          );
  
          this.metaTagService.updateTag(
            { name: 'og:image', content: this.MetaTagDataPast.OgImageURL}
          );
  
          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.MetaTagDataPast.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.MetaTagDataPast.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.MetaTagDataPast.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.MetaTagDataPast.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }

}
