// import { HttpClient } from '@angular/common/http';
// import { Route } from '@angular/compiler/src/core';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-single-whitepaper-downloadform',
  templateUrl: './single-whitepaper-downloadform.component.html',
  styleUrls: ['./single-whitepaper-downloadform.component.scss']
})
export class SingleWhitepaperDownloadformComponent implements OnInit {

  @Input() pDFUrl: string;
  @Input() resourceType: string;
  @Input() resourceID: number;
  downloadDatas: any;
  downloadForm: FormGroup;
  submitted = false;
  ipAddress: any;
  envSiteName:any;
  data:any;

  constructor(private formBuilder: FormBuilder, private httpclientService: HttpclientService, private http:HttpClient, private route: Router) {
   this.envSiteName=environment.siteName;
   }
  ngOnInit(): void {
    this.getIPAddress();
    this.downloadForm = this.formBuilder.group({
     
      email: ['', [Validators.required, Validators.email]],
     
      acceptTerms: [false, Validators.requiredTrue]
  });
  }
  
  get f() { return this.downloadForm.controls; }
  

  onSubmit() {
    this.submitted = true;
    if (this.downloadForm.invalid) {
        return;
    }
  
    // display form values on success
    console.log('SUCCESS!! :-)\n\n' + JSON.stringify(this.downloadForm.value, null, 4));
    console.log(this.resourceID);
   let objList = JSON.parse(JSON.stringify(this.downloadForm.value, null, 4));
   if(objList != null)
   {
      let p:DownloadModel = new DownloadModel(); 

      p.SiteName= this.envSiteName,
      p.ResourceID=this.resourceID,
      p.Email= objList.email,
      p.IPAddress = this.ipAddress,
      p.AcceptTermsOnDownload = objList.acceptTerms,
      p.ResourceType = this.resourceType, 
 

      this.httpclientService.postFormData('https://api.nathanark.com/api/SingleResources/SaveAssetDownload',  this.getFormUrlEncodedData(p))
      .subscribe(
        items => {   
          this.data=items;    
          console.log("item",this.data)   
          window.open(this.pDFUrl, "_blank");         
        });      
    }  

 
}

getFormUrlEncodedData(toConvert) {
  const formBody = [];
  for (const property in toConvert) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(toConvert[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  return formBody.join('&');
}

getIPAddress()
  {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });
  }
}
class DownloadModel { 
  SiteName:string;
  ResourceID:number;
  Email:string;
  IPAddress: string;
  AcceptTermsOnDownload: Boolean;
  ResourceType: string;
  }
