import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-other-webinar',
  templateUrl: './other-webinar.component.html',
  styleUrls: ['./other-webinar.component.scss']
})
export class OtherWebinarComponent implements OnInit {

  refreshEmit:any;
  @Output() refreshEmitter= new EventEmitter<string>();

  morewebinarLoaded:boolean;
  moreLiveEventDatas:any;
  href:any;
  lastString:any;
  envSiteName:any;
  shortSiteName:string;

  constructor(private httpclientService: HttpclientService,public router:Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.moreWebinartData();
  }

  refreshReq(EventID:any){
    
    this.resetUsers();
    
    this.refreshEmit=EventID;
    this.refreshEmitter.emit(this.refreshEmit);
    this.lastString=EventID;
    this.moreWebinartData();
  }

  
moreWebinartData(){
  this.shortSiteName=this.envSiteName.substr(0, this.envSiteName.indexOf('.')); 
  if(this.lastString==null){
  this.href = this.router.url;
  this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
}

console.log(this.lastString)
  this.morewebinarLoaded=false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleLiveWebinar/GetSingleLiveWebinarTop4?SiteName='+this.shortSiteName+'&EventID='+this.lastString)
    .subscribe(
      items => {
        this.moreLiveEventDatas = items;
        this.morewebinarLoaded=true;
        console.log("events moreEventDatas",this.moreLiveEventDatas)
      });
}
resetUsers(): void {
  this.moreLiveEventDatas = null;
  this.morewebinarLoaded = false;
  this.lastString=null;
  this.href=null;
}


}