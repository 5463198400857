import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-single-whitepaper-spotlight',
  templateUrl: './single-whitepaper-spotlight.component.html',
  styleUrls: ['./single-whitepaper-spotlight.component.scss']
})
export class SingleWhitepaperSpotlightComponent implements OnInit {
  loaded: boolean;
  spotLightDatas: any;
  envSiteName:any;
  constructor(private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.spotLightData()
  }
  spotLightData(): void
  {
    this.loaded =false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/SinglwWhitePaper/GetSingleWhitePaperSpotLight?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.spotLightDatas = items[0];
        this.loaded = true;
        console.log("SpotLight Resorces Data:", this.spotLightDatas);
      }
    );
  }
}
