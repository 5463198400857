import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpclientService } from '../../../../../src/app/modules/application/httpclient/httpclient.service';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss']
})
export class ThankYouPageComponent implements OnInit {
  scrollToTop: number;
  pos: number;
  preSite: any;
  loaded:boolean;
  iData:any;
  constructor(private httpclientService: HttpclientService,private route: Router) {
   }

  ngOnInit(): void {
    this.onActivate();
    this.LogoData();
    setTimeout(() => {
      /** spinner ends after 3 seconds */
      this.route.navigateByUrl('/');
    }, 3000);
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+window.location.hostname)
      .subscribe(
        (  items: any) => {
          this.iData = items;
          this.preSite = this.iData.PreSite;
          this.loaded = true;
        });
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}


}
