import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../../../src/environments/environment';

@Component({
  selector: 'app-other-articles',
  templateUrl: './other-articles.component.html',
  styleUrls: ['./other-articles.component.scss']
})
export class OtherArticlesComponent implements OnInit {

  refreshEmit:any;
  @Output() refreshEmitter= new EventEmitter<string>();

  
  moreArticlesLoaded:boolean;
  moreArticlesDatas:any;
  href:any;
  lastString:any;
  envSiteName:any;
  scrollToTop: number;
  pos: number;

  constructor(private httpclientService: HttpclientService,public router:Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.moreArticlesData();
  }

  refreshReq(Id:any){
    
    this.resetUsers();
    
    this.refreshEmit= Id;
    this.refreshEmitter.emit(this.refreshEmit);
    this.lastString= Id;
    this.moreArticlesData();
    
  }

  moreArticlesData(){
    if(this.lastString==null){
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
  }
    console.log("other articles:",this.lastString)
    this.moreArticlesLoaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleResources/GetTop4SingleResourceOtherArticles?siteName='+this.envSiteName+'&ID='+this.lastString)
      .subscribe(
        items => {
          this.moreArticlesDatas = items;
          this.moreArticlesLoaded=true;
          console.log("articleDatas:",this.moreArticlesDatas)
        });
  }

  resetUsers(): void {
    this.moreArticlesDatas = null;
    this.moreArticlesLoaded = false;
    this.onActivate();
    this.lastString=null;
    this.href=null;
  }

  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}

}

