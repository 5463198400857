import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../src/environments/environment';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  CompaniesMetaTagLoaded: boolean;
  CompaniesMetaTagData: any;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta)
   {this.envSiteName=environment.siteName;}

  ngOnInit(): void {
  this.CompaniesAllMetaData();
  }
  CompaniesAllMetaData(): void {
    if(this.CompaniesMetaTagLoaded!=true){
    this.CompaniesMetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'search-companies')
    .subscribe(
      items => {
        this.CompaniesMetaTagData = items[0];
        this.CompaniesMetaTagLoaded=true;
        console.log('this.EventMetaTagData',this.CompaniesMetaTagData);


          //seo code for tags
          this.titleService.setTitle(this.CompaniesMetaTagData.PageTitle);

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.CompaniesMetaTagData.OgDescription}
          );

          this.metaTagService.updateTag(
            { name: 'og:title', content: this.CompaniesMetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.CompaniesMetaTagData.OgImageURL}
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.CompaniesMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.CompaniesMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.CompaniesMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.CompaniesMetaTagData.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }

}
