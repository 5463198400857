<!-- Modal -->

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close pop-close" data-dismiss="modal" #closebutton aria-label="Close" (click)="onReset()">
                <span aria-hidden="true">&times;</span>
              </button>
            <div class="modal-body">
                <form [formGroup]="newsLetterForm" (ngSubmit)="onSubmit()">
                    <p class="pop-title text-capitalize text-center">Keep me plugged in with the best</p>
                    <p class="text-center pop-para">Join thousands of your peers and receive our weekly newsletter with the latest news, industry events, customer insights, and market intelligence.</p>
                    <div class="form-group">
                        <input formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" type="email" required class="form-control" id="txtEmailNewsLetter" aria-describedby="emailHelp" placeholder="*Email">

                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Required</div>
                            <div *ngIf="f.email.errors.email">Invalid email address</div>
                        </div>
                    </div>
                    <div class="form-check text-center mb-3">
                        <input type="checkbox" class="form-check-input" id="newsAcceptTerms" formControlName="newsAcceptTerms" [ngClass]="{ 'is-invalid': submitted && f.newsAcceptTerms.errors }">
                        <label class="form-check-label pop-para" for="newsAcceptTerms"> I agree to the <a href="" data-dismiss="modal" aria-label="Close" routerLink='/terms-and-service'>terms of service</a> and <a href="" data-dismiss="modal" aria-label="Close"
                            routerLink='/privacy-policy'>privacy Policy</a>.</label>

                        <div *ngIf="submitted && f.newsAcceptTerms.errors" class="invalid-feedback text-left">Required</div>

                    </div>
                    <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3"> Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>