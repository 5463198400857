<div class="row mt-3">
    <div class="col-md-9">
        <p class="para-2-c-2 TopSearch">Search Result Found For "{{keyword}}"</p>
        <div *ngIf="interviewLoad || newsLoad || companiesLoad || upcomingConferencesLoad || pastConferencesLoad || LiveWebinarLoad || OnDemandWebinarLoad || resourcesLoad ;then content else notfound"></div>
        <ng-template #content>
            <div *ngIf="interviewLoad">
                <p class="para-h1 text-uppercase ">Interviews</p>
                <div class="col-12 d-block p-0 SearchArt">
                    <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                        <div ngxSlickItem *ngFor="let interviewDatas of interviewData" class="slide pl-2 pr-2 SearchArtDiv">
                            <!-- <div class="cursor-p" (click)="interviewRedirect(interviewDatas.RouteURL)"> -->
                                  <div class="cursor-p">

                                <a href='{{interviewDatas.RouteURL}}' target="_blank">
                                   
                                <img class="img-fluid pb-2 image-hight-news" [src]="interviewDatas.InterviewImage" alt="news image">
                                <p class="para-2 mb-0 text-uppercase"></p>
                                <!-- <p class="para-2 mb-2">{{interviewDatas.Keywords}}</p> -->
                                <h4 class="text-height-3 card-header-height-3 text-uppercase" [innerHTML]="interviewDatas.InterviewTitle">
                                </h4>
                            </a>
                                <p class="para-2 mb-2">{{interviewDatas.CompanyName}} | {{interviewDatas.InterviewDate}}
                                </p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div *ngIf="newsLoad">
                <p class="para-h1 text-uppercase TopHead">News</p>
                <div class="col-12 d-block p-0 SearchArt">
                    <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideNews">
                        <div ngxSlickItem *ngFor="let newsDatas of newsData" class="slide pl-2 pr-2 SearchArtDiv">
                            <div class="cursor-p">
                                <a target="_blank" href='/{{newsDatas.NewsTypeName}}/{{newsDatas.RouteURL}}' >
                                <img class="img-fluid pb-2 image-hight-news" [src]="newsDatas.ImageUrl" alt="news image">
                                <p class="para-2 mb-2">{{newsDatas.Tag}}</p>
                                <h4 class="text-height-3 card-header-height-3 text-uppercase" [innerHTML]="newsDatas.Title">
                                </h4>
                                </a>
                                <p class="para-2 mb-2 text-capitalize">{{newsDatas.CompanyName}} | {{newsDatas.Date}}
                                </p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div *ngIf="companiesLoad">
                <p class="para-h1 text-uppercase TopHead">Companies</p>
                <div class="col-12 d-block p-0 SearchArt">
                    <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideCompanies">
                        <div ngxSlickItem *ngFor="let companiesDatas of companiesData" class="slide pl-2 pr-2 SearchArtDiv">
                            <div class="cursor-p">
                               <a target="_blank" href='/companies/{{companiesDatas.RouteURL}}'>
                                <img class="company-img" [src]="companiesDatas.logo" alt="news image">
                            
                            <h4 class="text-height-3 card-header-height-2 mt-2 text-uppercase" [innerHTML]="companiesDatas.company_name">
                            </h4>
                        </a>
                        </div>
                    </div>
                        </ngx-slick-carousel>
                    
                </div>
            </div>
            <div *ngIf="upcomingConferencesLoad || pastConferencesLoad || LiveWebinarLoad || OnDemandWebinarLoad">
                <p class="para-h1 text-uppercase TopHead">Events</p>
                <div *ngIf="upcomingConferencesLoad">
                    <h3 class="text-uppercase SubEvent">Upcoming Conferences</h3>
                    <div class="col-12 d-block p-0 SubEventDiv">
                        <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                            <div ngxSlickItem *ngFor="let upcomingConferencesDatas of upcomingConferencesData" class="slide pl-2 pr-2 eveSearchDiv">
                                <div class="cursor-p" >
                                    <a target="_blank" href='/upcoming-conferences/{{upcomingConferencesDatas.RouteURL}}' >
                                    <img class="img-fluid pb-2 image-hight-news" [src]="upcomingConferencesDatas.ImageUrl" alt="news image">
                                    <p class="para-2 mb-0"></p>
                                    <h4 class="text-height-3 card-header-height-2 text-uppercase" [innerHTML]="upcomingConferencesDatas.Name">
                                    </h4>
                                    </a>


                                    <p class="para-2 mb-2">{{upcomingConferencesDatas.StartEndDate}} | {{upcomingConferencesDatas.Country}}
                                    </p>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <div *ngIf="pastConferencesLoad">
                    <h3 class="text-uppercase SubEvent">Past Conferences</h3>
                    <div class="col-12 d-block p-0 SubEventDiv">
                        <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                            <div ngxSlickItem *ngFor="let pastConferencesDatas of pastConferencesData" class="slide pl-2 pr-2 eveSearchDiv">
                                <div class="cursor-p">
                                    <a target="_blank" href='/past-conferences/{{pastConferencesDatas.RouteURL}}'>
                                    <img class="img-fluid pb-2 image-hight-news" [src]="pastConferencesDatas.ImageUrl" alt="news image">
                                    <h4 class="text-height-3 card-header-height-2 text-uppercase" [innerHTML]="pastConferencesDatas.Name">
                                    </h4>
                                    </a>
                                    <p class="para-2 mb-2">{{pastConferencesDatas.StartEndDate}} | {{pastConferencesDatas.Country}}
                                    </p>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <div *ngIf="LiveWebinarLoad">
                    <h3 class="text-uppercase SubEvent">Live Webinar</h3>
                    <div class="col-12 d-block p-0 SubEventDiv">
                        <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                            <div ngxSlickItem *ngFor="let LiveWebinarDatas of LiveWebinarData" class="slide pl-2 pr-2 eveSearchDiv">
                                <div class="cursor-p">
                                    <a  target="_blank" href='/live-webinars/{{LiveWebinarDatas.RouteURL}}'>
                                    <img class="img-fluid pb-2 image-hight-news" [src]="LiveWebinarDatas.ImageUrl" alt="news image">
                                    <h4 class="text-height-3 card-header-height-3 text-uppercase" [innerHTML]="LiveWebinarDatas.Name">
                                    </h4>
                                    </a>
                                    <p class="para-2 mb-2">{{LiveWebinarDatas.StartEndDate}} | {{LiveWebinarDatas.Timezone}}
                                    </p>

                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <div *ngIf="OnDemandWebinarLoad">
                    <h3 class="text-uppercase SubEvent">On Demand Webinar</h3>
                    <div class="col-12 d-block p-0 SubEventDiv">
                        <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                            <div ngxSlickItem *ngFor="let OnDemandWebinarDatas of OnDemandWebinarData" class="slide pl-2 pr-2">
                                <div class="cursor-p" >
                                    <a target="_blank" href='{{OnDemandWebinarDatas.RouteURL}}'>
                                    <img class="img-fluid image-hight-news" [src]="OnDemandWebinarDatas.ImageUrl" alt="news image">
                                    <h4 class="text-height-3 card-header-height-3 text-uppercase" [innerHTML]="OnDemandWebinarDatas.Name">
                                    </h4>
                                 </a>
                                    <p class="para-2 mb-2">{{OnDemandWebinarDatas.Company}} 
                                    </p>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
            </div>
            <div *ngIf="resourcesLoad">
                <p class="para-h1 text-uppercase TopHead">Resources</p>
                <div class="col-12 d-block p-0 SearchArt">
                    <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                        <div ngxSlickItem *ngFor="let resourcesDatas of resourcesData" class="slide pl-2 pr-2 SearchArtDiv">

                            <div class="cursor-p" >
                                <a target="_blank" href='{{resourcesDatas.RouteURL}}'>
                                <img class="img-fluid pb-2 image-hight-news" [src]="resourcesDatas.ImageUrl" alt="news image">

                                <h4 class="text-height-3 card-header-height-3 text-uppercase" [innerHTML]="resourcesDatas.WhitePaperTitle">
                                </h4>
                                </a>
                                <p class="para-2 mb-2 text-capitalize">{{resourcesDatas.ResourceType}} | {{resourcesDatas.PublishingDate}}
                                </p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </ng-template>
        <ng-template #notfound>
            <div>
                <p class="para-h1 text-uppercase mb-1">Search Result</p>
                <hr>
                <p class="para-2 text-center">No Result Found</p>
            </div>
        </ng-template>
    </div>
    <div class="col-md-3 text-center" *ngIf="loaded">
        <h2 class="text-uppercase mb-3 SearchSpotL spotGDiv">Spotlight</h2>
        <div class="text-center spotN spotGDiv ">
            <a href="{{spotLightDatas.URL}}" target="_blank">
                <img class="img-fluid" [src]="spotLightDatas.ImageUrl" alt="Responsive image">
                <h4 class="text-uppercase mt-2 text-center">{{spotLightDatas.Name}}</h4>
            </a>
            <p class="text-justify para-1 spotlight-text-height" [innerHTML]=" spotLightDatas.Description"></p>
        </div>
    </div>
</div>