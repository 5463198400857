import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'app-single-c-suiteondeck',
  templateUrl: './single-c-suiteondeck.component.html',
  styleUrls: ['./single-c-suiteondeck.component.scss']
})
export class SingleCSuiteondeckComponent implements OnInit {
  @Input() DomainName : string;
  imgDefault: string;
  href:string;
  contactUs: boolean;
  lastString:string;
  singleCSuiteLoaded:  boolean;
  singleCSuite: any;
  envSiteName:any;
  firstString: string;
  constructor(private httpclientService: HttpclientService,public router:Router) {
    this.envSiteName=environment.siteName;
   }
  ngOnInit(): void {
    this.singleCSuiteData();
  }
  redirect(getUrl:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.router.navigate([getUrl]);
    }
  }
  singleCSuiteData(): void{
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);
    this.singleCSuiteLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleCompanies/GetTop1SingleCompaniesCSuiteOnDeck?siteName=' +this.envSiteName+'&CompanyDomian='+ this.DomainName)
      .subscribe(
        items => {
          
          this.singleCSuite = items;
            if(this.singleCSuite.length == 0)
            {
              this.singleCSuiteLoaded = false;
              this.imgDefault = "https://"+this.envSiteName+"/images/interview/"+this.envSiteName.split('.')[0].trim()+".jpg";
              this.contactUs = true;
            }
            else{
              this.singleCSuite = null;
              this.singleCSuite = items[0];
              this.singleCSuiteLoaded = true;
              this.imgDefault = " ";
              this.contactUs = false;
            }
        
          console.log("single CSuite", this.singleCSuite);
        });
  }
  resetUsers(): void {
    this.singleCSuite = null;
    this.singleCSuiteLoaded = false;
    this.lastString = null;
    this.href = null;
  }
}
