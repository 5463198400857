import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; //import router
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { ActivatedRoute } from '@angular/router';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../../src/environments/environment';


@Component({
  selector: 'app-webinar-single-page',
  templateUrl: './webinar-single-page.component.html',
  styleUrls: ['./webinar-single-page.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '200px',
        opacity: 1,
        display:'block',
      })),
      state('closed', style({
        width: '0px',
        opacity: 0,
        display:'none',
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class WebinarSinglePageComponent implements OnInit {

  href:string;
  lastString:string;
  firstString:string;
  receiveRefData:any;
  singleWebinarLoaded:boolean;
  singleWebinar:any;
  singleEvents:any;
  eventsEventID:any;
  webinarsid:any;
  isOpen = false;
  isOverlay = false;
  fullUrl:any;
  envSiteName:any;
  shortSiteName:string;
  titlePrefix:string;
  loaded:boolean;
  iData:any;

  constructor(private httpclientService: HttpclientService,public router:Router,private titleService: Title,private metaTagService: Meta, private activatedRoute: ActivatedRoute) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.singleWebinarsData();
    this.LogoData();
    this.fullUrl=window.location.href;
    this.webinarsid = this.activatedRoute.snapshot.params.id;
  }
  LogoData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+window.location.hostname)
      .subscribe(
        (  items: any) => {
          this.iData = items;
          this.titlePrefix = this.iData.PreSite;
          this.loaded = true;
        });
  }

  receiveRefresh($event){
    this.resetUsers();
    this.eventsEventID=$event;
     this.singleWebinarsData();
    
  }
     

  
  singleWebinarsData(){
    this.shortSiteName=this.envSiteName.substr(0, this.envSiteName.indexOf('.')); 
    if(this.eventsEventID != null){
      this.webinarsid=this.eventsEventID;
  }else{
    this.webinarsid =  this.activatedRoute.snapshot.params.id;
  }
    this.singleWebinarLoaded=false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/SingleLiveWebinar/GetSingleLiveWebinarById?SiteName='+this.shortSiteName+'&EventID='+this.webinarsid)
      .subscribe(
        items => {
          this.singleWebinar = items[0];
          this.singleWebinarLoaded=true;
          console.log(this.singleWebinar);

       //seo code for tags
       this.titleService.setTitle(this.singleWebinar.Name+' | '+this.titlePrefix);
      this.metaTagService.updateTag(
      { name: 'og:description', content: this.singleWebinar.Details}
     );
      this.metaTagService.updateTag(
      { name: 'og:title', content: this.singleWebinar.Name+' | '+this.titlePrefix}
      );
     this.metaTagService.updateTag(
     { name: 'og:image', content: this.singleWebinar.ImageUrl}
 );
 this.metaTagService.updateTag(
   { name: 'twitter:description', content: this.singleWebinar.Details}
 );
 this.metaTagService.updateTag(
   { name: 'twitter:title', content: this.singleWebinar.Name+' | '+this.titlePrefix}
 );
 this.metaTagService.updateTag(
   { name: 'twitter:image', content: this.singleWebinar.ImageUrl}
 );
 //seo code for tags end


        });
    }

    shareClick(){
      this.isOpen = !this.isOpen;
    }
  
      resetUsers(): void {
        this.singleEvents = null;
        this.singleWebinarLoaded = false;
        this.lastString=null;
        this.href=null;
      }

      redirect(getUrl:any,getId:any)
  {
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https")
    {
      window.open(getUrl, "_blank");
    }else
    {
      this.router.navigate(['/events/live-webinars/'+getUrl+"/"+getId]);
    }
  }
}
